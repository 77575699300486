<template>
  <div class="height-100">
    <!-- 导航 -->
    <loading v-if="loading"></loading>
    <div>


      <div v-if="!loading">
        <div style="padding: 0 0.2rem;" v-if="list.length > 0">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-list v-if="list.length > 0" v-model="loading2" :finished="finished" finished-text="没有更多了" @load="onLoad">
              <complet-order :list="list">
              </complet-order>
            </van-list>
          </van-pull-refresh>
        </div>
        <div class="empt" v-else>
          <!-- <img src="@/assets/images/logo_loading.png">
          <span style="margin-top: 1rem;">暂无订单</span> -->
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import CompletOrder from "../../components/order/CompletOrder.vue";
export default {
  name: "",
  props: {},
  data() {
    return {
      isLoading: false,
      loading: true,
      loading2: false,
      finished:false,
      list: [
        // {
        //   order_id: "123",
        //   order_list: [{
        //     image_path: "//game.gtimg.cn/images/yxzj/img201606/skin/hero-info/150/150-bigskin-6.jpg",
        //     present_price: "123",
        //     name: " 不知道",
        //     count: "2",
        //   }],
        //   add_time: "时间",
        //   address: "地址1111",
        //   mallPrice: '535',
        // },
      ],
      title: ["全部", "待支付", "已取消", "已完成"],
      form: {
        productType: '2',
        status: '',
        current: 1,
        size: 10,
        userId: '',
      },
      active: 1,
    };
  },
  components: { CompletOrder },
  methods: {
    // beforeChange() {
    //   // 返回 false 表示阻止此次切换
    //   if (this.loading) {
    //     return false;
    //   }
    // },
    //	0待支付 1待发货 2已发货 3已完成 4已关闭 5超时
    onClick() {
      if (this.active == 0) {
        this.form.status = ''
      } else if (this.active == 1) {
        this.form.status = '0'
      } else if (this.active == 2) {
        this.form.status = '4'
      } else {
        this.form.status = '3'
      }
      this.list.length = 0
      this.form.current = 1
      this.loading = true
      this.getMyOrder()

    },
    onRefresh() {
      this.form.current = 1
      this.getMyOrder()
    },
    onLoad() {
      this.form.current = this.form.current + 1
      this.getMyOrder()
    },
    // //   获取订单信息
    getMyOrder() {
      this.$api
        .queryMovieOrderInfo(this.form)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          this.list = res.data.records
          if (this.form.current >= res.data.pages) {
            this.finished = true
          }
          this.loading = false
          this.isLoading = false
          this.loading2 = false
        })
        .catch((err) => {
          this.loading2 = false
          this.finished = true
          this.loading = false
          this.isLoading = false
        });
    },
  },
  //详情页获取参数

  mounted() {
    // 
    this.form.userId = JSON.parse(localStorage.getItem("userInfo")).id
    this.active = Number(this.$route.query.active) ? Number(this.$route.query.active) : 0
    this.getMyOrder();
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.empt {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #000;
}
</style>